<template>
  <v-card class="elevation-0" :color="color.BG" >
    <v-card-text class="pa-0">
      <v-container fluid>
        <v-layout row wrap justify-center v-if="system !== null && status_aspect_key === 'Y'">
          <v-flex lg12 xs12>
            <v-expansion-panels v-model="panel" multiple outlined>
              <v-expansion-panel class="pa-1">
                <v-expansion-panel-header>
                  <v-layout row wrap align-center>
                    <v-flex lg2 xs6>
                      <span :style="headerPage">{{ $t('dcmPage.conditionFile')}}</span>
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-1">
                  <v-form>
                    <v-layout row wrap fill-height justify-center>
                      <v-flex xs12 sm12 md5 lg5 class="pa-1">
                        <v-container fluid>
                          <v-layout row wrap justify-center>
                            <!-- ช่องกรอกชื่อไฟล์ -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0"
                                v-model="filename"
                                :label="$t('myoutboxPage.conditionOptional.filename')"
                                prepend-icon="insert_drive_file"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex>
                              <!-- ช่องเลือก ประเภทผู้รับ -->
                            <!-- <v-flex xs12 sm12 md6 lg6>
                              <v-select
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0"
                                v-model="recivertype"
                                :items="listReciver"
                                item-value="reciver_type"
                                item-text="reciver_type"
                                :label="$t('myoutboxPage.conditionOptional.receivernameextension')"
                                prepend-icon="line_style"
                                :color="color.theme"
                                :item-color="color.theme"
                              ></v-select>
                            </v-flex> -->
                            <!-- ช่องเลือก ประเภเอกสาร -->
                              <v-flex xs12 sm12 md6 lg6>
                            <v-select
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="documenttype"
                              :items="folderid_yourfolder"
                              item-value="document_type"
                              item-text="document_type"
                              :label="$t('myinboxPage.conditionOptional.doctype')"
                              prepend-icon="line_style"
                              :color="color.theme"
                              :item-color="color.theme"
                            ></v-select>
                          </v-flex>
                          </v-layout>
                          <v-layout row wrap justify-center>
                            <!-- ช่องวันที่ได้รับไฟล์ -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-dialog
                                ref="dialogFromdateupload"
                                v-model="modalFromdateupload"
                                :return-value.sync="fromdateupload"
                                persistent
                                full-width
                                width="290px"
                                :color="color.theme"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    clearable
                                    :color="color.theme"
                                    :label="$t('myoutboxPage.conditionOptional.fromdateupload')"
                                    v-model="fromdateupload"
                                    @click:prepend="modalFromdateupload = true"
                                    prepend-icon="event"
                                    v-on="on"
                                    readonly
                                    outlined
                                    dense
                                    @click:clear="todateupload = ''"
                                    class="my-hint-style pa-1 pt-0 pb-0"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="fromdateupload"
                                  scrollable
                                  :locale="$t('default')"
                                  @input="changeFromdateupload()"
                                  :color="color.theme"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click.stop="modalFromdateupload = false"
                                  >{{$t('myoutboxPage.conditionOptional.closefromdateupload')}}</v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>
                            <!-- ถึง -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-dialog
                                ref="dialogTodateupload"
                                v-model="modalTodateupload"
                                :return-value.sync="todateupload"
                                persistent
                                full-width
                                width="290px"
                                :color="color.theme"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    clearable
                                    readonly
                                    :color="color.theme"
                                    :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                    v-model="todateupload"
                                    prepend-icon="event"
                                    @click:prepend="modalTodateupload = true"
                                    v-on="on"
                                    outlined
                                    dense
                                    class="my-hint-style pa-1 pt-0 pb-0"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="todateupload"
                                  scrollable
                                  :locale="$t('default')"
                                  @input="$refs.dialogTodateupload.save(todateupload)"
                                  :min="fromdateupload"
                                  :color="color.theme"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click.stop="modalTodateupload = false"
                                  >{{$t('myoutboxPage.conditionOptional.closetodateupload')}}</v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap justify-center>
                            <v-flex xs12 sm12 md6 lg6>
                              <!-- ช่องกรอก ชื่อfolder-->
                              <!-- <v-text-field
                                outlined
                                dense
                                clearable
                                class="pa-1 pt-0 pb-0"
                                v-model="foldername"
                                :label="$t('myoutboxPage.conditionOptional.namefolder')"
                                prepend-icon="account_circle"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex> -->
                              <v-text-field
                                outlined
                                dense
                                clearable
                                class="pa-1 pt-0 pb-0"
                                v-model="customername"
                                :label="$t('myoutboxPage.conditionOptional.namecustomer')"
                                prepend-icon="account_circle"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex>
                            <!-- cusmoterid -->
                          <v-flex xs12 sm12 md6 lg6>
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0"
                                v-model="customer_id"
                                :label="$t('myoutboxPage.conditionOptional.customerid')"
                                prepend-icon="account_circle"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>

                      <v-flex xs12 sm12 md1 lg1 class="text-center">
                        <v-divider vertical></v-divider>
                      </v-flex>

                      <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                        <v-container fluid>
                          <v-layout row wrap justify-center>
                            <v-flex xs12 sm12 md6 lg6>
                              <v-text-field
                                outlined
                                clearable
                                dense
                                class="pa-1 pt-0 pb-0"
                                v-model="documentid"
                                :label="$t('myoutboxPage.conditionOptional.filedocumentid')"
                                prepend-icon="format_list_numbered"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md6 lg6>
                              <v-text-field
                                outlined
                                clearable
                                dense
                                class="pa-1 pt-0 pb-0"
                                v-model="documentidto"
                                :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                prepend-icon="format_list_numbered"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap justify-center>
                            <!-- ช่องกรอก เอกสารลงวันที่ -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-dialog
                                ref="dialogFromdatedocument"
                                v-model="modalFromdatedocument"
                                :return-value.sync="fromdatedocument"
                                persistent
                                full-width
                                width="290px"
                                :color="color.theme"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    :color="color.theme"
                                    :label="$t('myoutboxPage.conditionOptional.fromdatedocument')"
                                    v-model="fromdatedocument"
                                    @click:prepend="modalFromdatedocument = true"
                                    prepend-icon="event"
                                    v-on="on"
                                    readonly
                                    outlined
                                    dense
                                    clearable
                                    :persistent-hint="fromdate_hint"
                                    @click:clear="todatedocument = ''"
                                    class="my-hint-style pa-1 pt-0 pb-0"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="fromdatedocument"
                                  scrollable
                                  :locale="$t('default')"
                                  @input="changeFromdatedocument()"
                                  :color="color.theme"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click.stop="modalFromdatedocument = false"
                                  >{{$t('myoutboxPage.conditionOptional.closefromdatedocument')}}</v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>

                            <!-- ช่องกรอก เอกสารลงวันที่ -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-dialog
                                ref="dialogTodatedocument"
                                v-model="modalTodatedocument"
                                :return-value.sync="todatedocument"
                                persistent
                                full-width
                                width="290px"
                                :color="color.theme"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    readonly
                                    :color="color.theme"
                                    :label="$t('myoutboxPage.conditionOptional.todatedocument')"
                                    v-model="todatedocument"
                                    prepend-icon="event"
                                    @click:prepend="modalTodatedocument = true"
                                    v-on="on"
                                    outlined
                                    dense
                                    clearable
                                    :persistent-hint="todate_hint"
                                    class="my-hint-style pa-1 pt-0 pb-0"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="todatedocument"
                                  scrollable
                                  :locale="$t('default')"
                                  @input="$refs.dialogTodatedocument.save(todatedocument)"
                                  :min="fromdatedocument"
                                  :color="color.theme"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click.stop="modalTodatedocument = false"
                                  >{{$t('myoutboxPage.conditionOptional.closetodatedocument')}}</v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap justify-center>
                            <v-flex xs12 sm12 md6 lg6>
                              <v-text-field
                                outlined
                                clearable
                                dense
                                class="pa-1 pt-0 pb-0"
                                v-model="buyertaxid"
                                :label="$t('myinboxPage.conditionOptional.buyertaxid_')"
                                prepend-icon="mdi-domain"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md6 lg6>
                              <v-text-field
                                outlined
                                clearable
                                dense
                                class="pa-1 pt-0 pb-0"
                                v-model="buyerbranchid"
                                :label="$t('myinboxPage.conditionOptional.buyerbranchid_')"
                                prepend-icon="mdi-format-list-bulleted-square"
                                :color="color.theme"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 sm12 md6 lg6 class="text-center">
                      <v-btn
                        class="ma-1"
                        :color="color.theme"
                        :dark="color.darkTheme"
                        :disabled="loaddataprogress"
                        :loading="loaddataprogress"
                        @click="searchdata()"
                      >
                        <v-icon left>search</v-icon>
                        &nbsp;
                        {{ $t('myoutboxPage.search')}}
                      </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import { saveAs } from "file-saver";
import format from "date-fns/format";

export default {
  props:["system","status_aspect_key","folderid_yourfolder"],
    data: function () {
      return{
        panel: false,
        fromdate_hint: "",
        todate_hint: "",
        filename: "",
        fileextension: "",
        documentid: "",
        documentidto: "",
        recivertype: "",
        // foldername: "",
        customername:"",
        customer_id:"",
        buyertaxid: "",
        buyerbranchid: "",
        fromdateupload: "",
        modalFromdateupload: false,
        todateupload: "",
        modalTodateupload: false,
        fromdatedocument: "",
        modalFromdatedocument: false,
        todatedocument: "",
        documenttype:"",
        modalTodatedocument: false,
        loaddataprogress: false,
        listExtensionFile: [ {
          data_type: "pdf"
        },
        {
          data_type: "xml"
        }],
        listReciver: [ {
          reciver_type: "มี"
        },
        {
          reciver_type: "ไม่มี"
        }],
      }  
    },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
     headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    getdoctype(){
      payload = {
        folder_id : this.folderid_yourfolder,
        system: ""
      }
      console.log("folderid_yourfolder",payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE +
            "/api/file_get_all_doctype",
          payload
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // Function บันทึกวันที่ลงตัวแปรวันที่อัปโหลด
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = format(new Date(), "yyyy-MM-dd");
    },
       // Function บันทึกวันที่ลงตัวแปรเอกสารที่ลงวันที่
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      this.todatedocument = format(new Date(), "yyyy-MM-dd");
    },
    searchdata(){
      this.$emit("datafilter",{filename:this.filename!== null ? this.filename : "" ,
      fileextension:this.fileextension !== null ? this.fileextension : "" ,
      documentid:this.documentid !== null ? this.documentid :  "",
      documentidto:this.documentidto !== null ? this.documentidto :  "",
      recivertype:this.recivertype !== null ? this.recivertype :  "",
      // foldername:this.foldername !== null ? this.foldername :  "",
      customername:this.customername !== null ? this.customername : "",
      customer_id:this.customer_id !== null ? this.customer_id : "",
      buyertaxid:this.buyertaxid !== null ? this.buyertaxid :  "",
      buyerbranchid:this.buyerbranchid !== null ? this.buyerbranchid :  "",
      fromdateupload:this.fromdateupload !== null ? this.fromdateupload :  "",
      todateupload:this.todateupload !== null ? this.todateupload :  "",
      fromdatedocument:this.fromdatedocument !== null ? this.fromdatedocument :  "",
      todatedocument:this.todatedocument !== null ? this.todatedocument :  "",
      documenttype:this.documenttype !== null ? this.documenttype : ""
      })
       this.panel = false;
      //  this.filename = ''; 
      //  this.fileextension = '';
      //  this.documentid = '';
      //  this.documentidto = '';
      //  this.recivertype = '';
      //  this.foldername = '';
      //  this.buyertaxid = '';
      //  this.buyerbranchid = '';
      //  this.fromdateupload = '';
      //  this.todateupload = '';
      //  this.fromdatedocument = '';
      //  this.todatedocument = '';

    }
  },
  mounted() {    
   
  },
  
};
</script>
<style>
</style>